import React, { useRef, useEffect, useState } from "react";
import { Container, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import logo from "../../assets/images/logoappred.png";
import { NavLink, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { cartUiActions } from "../../store/shopping-cart/cartUiSlice";
import "../../styles/header.css";

const nav__links = [
  {
    display: "Home",
    path: "/home",
  },
  // {
  //   display: "Vendors",
  //   path: "/vendors",
  // },
  // {
  //   display: "Cart",
  //   path: "/cart",
  // },
  {
    display: "Contact",
    path: "/contact",
  },
];

const Header = () => {
  const menuRef = useRef(null);
  const headerRef = useRef(null);
  const totalQuantity = useSelector((state) => state.cart.totalQuantity);
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleMenu = () => menuRef.current.classList.toggle("show__menu");
  const toggleCart = () => dispatch(cartUiActions.toggle());
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  useEffect(() => {
    const handleScroll = () => {
      if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
        headerRef.current.classList.add("header__shrink");
      } else {
        headerRef.current.classList.remove("header__shrink");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header className="header" ref={headerRef}>
      <Container>
        <div className="d-flex align-items-center justify-content-between">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>

          <div className="navigation" ref={menuRef} onClick={toggleMenu}>
            <div className="menu d-flex align-items-center gap-5">
              {nav__links.map((item, index) => (
                <NavLink
                  to={item.path}
                  key={index}
                  className={(navClass) => navClass.isActive ? "active__menu" : ""}
                >
                  {item.display}
                </NavLink>
              ))}
            </div>
          </div>

          <div className="nav__right d-flex align-items-center gap-4">
            <span className="cart__icon" onClick={toggleCart}>
              <i className="ri-shopping-basket-line"></i>
              <span className="cart__badge">{totalQuantity}</span>
            </span>
            <NavLink
                  to={"/"}
                  
                  className={(navClass) => navClass.isActive ? "activem" : ""}
                >
                  Login
                </NavLink>
                <NavLink
                  to={"/"}
                  
                  className={(navClass) => navClass.isActive ? "activem" : ""}
                >
                  Sign up
                </NavLink>
            {/* <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
              <DropdownToggle tag="span" className="user" onClick={toggleDropdown}>
             <p>Account</p>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem tag={Link} to="/profile">Profile</DropdownItem>
                <DropdownItem tag={Link} to="/settings">Address</DropdownItem>
                <DropdownItem divider />
                <DropdownItem tag={Link} to="/profile">Register</DropdownItem>
                <DropdownItem tag={Link} to="/profile">Login</DropdownItem>
                <DropdownItem tag={Link} to="/logout">Logout</DropdownItem>
              </DropdownMenu>
            </Dropdown> */}

            <span className="mobile__menu" onClick={toggleMenu}>
              <i className="ri-menu-line"></i>
            </span>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;