import React, { useState, useRef } from "react";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/common-section/CommonSection";
import { Container, Row, Col } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios"; // Ensure axios is installed

const Login = () => {
  const [loginMethod, setLoginMethod] = useState("email");
  const emailRef = useRef();
  const phoneNumberRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    setError("");

    const password = passwordRef.current.value;
    const deviceId = "browser-" + Math.random().toString(36).substr(2, 9); // Generate a simple device ID

    let loginData = {
      password,
      deviceId
    };

    if (loginMethod === "email") {
      loginData.email = emailRef.current.value;
    } else {
      loginData.phoneNumber = phoneNumberRef.current.value;
    }

    try {
      const response = await axios.post("/api/auth/login", loginData, {
        withCredentials: true // Necessary for cookies to be set
      });

      if (response.data.isAuthenticated) {
        // Handle successful login
        console.log("Login successful", response.data.userDetails);
        // Redirect to dashboard or home page
        navigate("/dashboard");
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message.msg);
      } else {
        setError("An error occurred. Please try again.");
      }
    }
  };

  return (
    <Helmet title="Login">
      <CommonSection title="Login" />
      <section>
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12" className="m-auto text-center">
              <form className="form mb-5" onSubmit={submitHandler}>
                <div className="form__group">
                  <select onChange={(e) => setLoginMethod(e.target.value)} value={loginMethod}>
                    <option value="email">Email</option>
                    <option value="phone">Phone Number</option>
                  </select>
                </div>
                {loginMethod === "email" ? (
                  <div className="form__group">
                    <input
                      type="email"
                      placeholder="Email"
                      required
                      ref={emailRef}
                    />
                  </div>
                ) : (
                  <div className="form__group">
                    <input
                      type="tel"
                      placeholder="Phone Number"
                      required
                      ref={phoneNumberRef}
                    />
                  </div>
                )}
                <div className="form__group">
                  <input
                    type="password"
                    placeholder="Password"
                    required
                    ref={passwordRef}
                  />
                </div>
                {error && <div className="error-message">{error}</div>}
                <button type="submit" className="addTOCart__btn">
                  Login
                </button>
              </form>
              <Link to="/register">
                Don't have an account? Create an account
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Login;