import React, { useState, useEffect } from "react";
import Helmet from "../components/Helmet/Helmet.js";
import { Container, Row, Col, ListGroup, List, Form, FormGroup, Input, Button } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

import { 
   Card, 
  CardBody, 
  CardTitle, 
  CardText 
} from 'reactstrap';


// Import images
import heroImage from '../assets/images/hero.png';
import feature1Icon from '../assets/images/service-01.png';
import feature2Icon from '../assets/images/service-02.png';
import feature3Icon from '../assets/images/service-03.png';
import vendorImage from '../assets/images/network.png';
import customerImage from '../assets/images/location.png';

import heroImg from "../assets/images/hero.png";
import "../styles/hero-section.css";
import "./style.css";

import Category from "../components/UI/category/Category.jsx";
import "../styles/home.css";

import featureImg01 from "../assets/images/service-01.png";
import featureImg02 from "../assets/images/service-02.png";
import featureImg03 from "../assets/images/service-03.png";

import products from "../assets/fake-data/products.js";
import foodCategoryImg01 from "../assets/images/hamburger.png";

import ProductCard from "../components/UI/product-card/ProductCard.jsx";
import api from "../api/api.js";
import LandingPage from "../components/Landingpage.jsx";

const featureData = [
  {
    title: "Quick Delivery",
    imgUrl: featureImg01,
    desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, doloremque.",
  },
  {
    title: "Super Dine In",
    imgUrl: featureImg02,
    desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, doloremque.",
  },
  {
    title: "Easy Pick Up",
    imgUrl: featureImg03,
    desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, doloremque.",
  },
];

const Home = () => {
  const [category, setCategory] = useState("ALL");
  const [allProducts, setAllProducts] = useState(products);
  const [address, setAddress] = useState("");
  const [nearbyVendors, setNearbyVendors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Fetch nearby vendors based on address
  const fetchNearbyVendors = async () => {
    if (!address) {
      alert("Please enter an address");
      return;
    }

    setIsLoading(true);
    try {
      // You'll need to adjust the payload structure based on your API requirements
      const payload = {
        address: address,
        // Add any other required parameters like lat/long, radius, etc.
      };

      const response = await api.post('users/v/nearbyvendors', payload);
      
      if (response.data && response.data.vendors) {
        setNearbyVendors(response.data.vendors);
        // Optionally navigate to a vendors page or show results inline
        navigate('/vendors', { state: { vendors: response.data.vendors } });
      } else {
        alert("No vendors found near this address");
      }
    } catch (error) {
      console.error('Failed to fetch nearby vendors:', error);
      alert("Failed to find nearby vendors. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Existing category filtering logic
  useEffect(() => {
    if (category === "ALL") {
      setAllProducts(products);
    } else {
      const filteredProducts = products.filter(
        (item) => item.category === category
      );
      setAllProducts(filteredProducts);
    }
  }, [category]);


  const [email, setEmail] = useState('');

  const features = [
    {
      icon: feature1Icon,
      title: 'Quick Delivery',
      description: 'Get your orders delivered faster than ever with our efficient delivery system.',
    },
    {
      icon: feature2Icon,
      title: 'Easy Ordering',
      description: 'Simple, intuitive interface makes ordering from your favorite vendors a breeze.',
    },
    {
      icon: feature3Icon,
      title: 'Wide Vendor Network',
      description: 'Choose from hundreds of local vendors and restaurants in your area.',
    }
  ];

  return (



    <Helmet title="Home">
    
      <div className="landing-page">
      {/* Hero Section */}
      <section className="hero-section">
      <div className="hero-overlay"></div>
      
      {/* <Container className="hero-content">
        <Row className="align-items-center">
          <Col md="12">
            <h1 className="hero-title">
              Order from Local Vendors <br />
              <span className="text-primary">Delivered to Your Doorstep</span>
            </h1>
            <p className="hero-subtitle">
              Connect with local vendors, discover new favorites, 
              and enjoy hassle-free delivery with just a few clicks.
            </p>
            
            <div className="d-flex gap-3">
              <Button color="primary" size="lg" tag={Link} to="/home">
                Get Started
              </Button>
              <Button color="outline-primary" size="lg" tag={Link} to="/about">
                Learn More
              </Button>
            </div>
          </Col>
        </Row>
      </Container> */}

      {/* <Container className="hero-search">
        <Form className="mt-4">
          <FormGroup>
            <Input 
              type="text" 
              name="address" 
              id="addressInput" 
              placeholder="Enter your delivery address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="address-input"
            />
          </FormGroup>
          <Button 
            color="primary" 
            onClick={fetchNearbyVendors}
            disabled={isLoading}
          >
            {isLoading ? 'Searching...' : 'Find Nearby Vendors'}
          </Button>
        </Form>
      </Container> */}

      <Container className="hero-download">
        <h2 className="hero-download-title">
          Ready to Explore Local Flavors?
        </h2>
        <p className="hero-download-subtitle">
          Download our app and start your delicious journey today!
        </p>
        <p className="hero-download-subtitle">For Android users, the app is safe to download and use.The provided link directs to
          the official version while we work on making it available on the Google Play Store.</p>
        <div className="d-flex justify-content-center gap-3">
          <Button 
            color="light" 
            size="lg" 
            href="https://apps.apple.com/us/app/ofxpress/id6705132875"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="ri-apple-fill me-2"></i> App Store
          </Button>
          <Button 
            color="light" 
            size="lg" 
            href="https://drive.google.com/file/d/1_mprwxjWUVjrE32p9qau-QmpkJ9GZkQk/view?usp=drivesdk"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="ri-android-fill me-2"></i> Google Play
          </Button>
        </div>
      </Container>
    </section>
       
      {/* Features Section */}
      <section className="features-section py-5">
        <Container>
          <Row className="text-center mb-5">
            <Col>
              <h2 className="display-5">Why Choose OfXpress?</h2>
              <p className="text-muted">
                We're transforming local food ordering and delivery
              </p>
            </Col>
          </Row>
          <Row>
            {features.map((feature, index) => (
              <Col md="4" key={index} className="mb-4">
                <Card className="h-100 border-0 text-center">
                  <div className="text-center py-3">
                    <img 
                      src={feature.icon} 
                      alt={feature.title} 
                      style={{maxWidth: '100px', height: 'auto'}}
                    />
                  </div>
                  <CardBody>
                    <CardTitle tag="h5">{feature.title}</CardTitle>
                    <CardText>{feature.description}</CardText>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* Vendor Showcase Section */}
      <section className="vendor-section bg-light py-5">
        <Container>
          <Row className="align-items-center">
            <Col md="6">
              <img 
                src={vendorImage} 
                alt="Vendor Network" 
                className="img-fluid rounded shadow" 
              />
            </Col>
            <Col md="6">
              <h2 className="display-5 mb-4">
                Connecting You with Local Vendors
              </h2>
              <p className="lead mb-4">
                Discover a wide range of local restaurants, cafes, and specialty food vendors 
                in your neighborhood. From quick bites to gourmet meals, we've got you covered.
              </p>
              <ul className="list-unstyled">
                <li className="mb-2">✓ Diverse Vendor Selection</li>
                <li className="mb-2">✓ Real-time Menu Updates</li>
                <li className="mb-2">✓ Transparent Pricing</li>
              </ul>
              {/* <Button color="primary" size="lg" tag={Link} to="/vendors">
                Explore Vendors
              </Button> */}
            </Col>
          </Row>
        </Container>
      </section>

      {/* Customer Experience Section */}
      {/* <section className="customer-section py-5">
        <Container>
          <Row className="align-items-center">
            <Col md="6">
              <h2 className="display-5 mb-4">
                Seamless Customer Experience
              </h2>
              <p className="lead mb-4">
                We prioritize your convenience with user-friendly features 
                and exceptional customer support.
              </p>
          
             
            </Col>
            <Col md="6">
              <img 
                src={customerImage} 
                alt="Customer Experience" 
                className="img-fluid rounded shadow" 
              />
            </Col>
          </Row>
        </Container>
      </section> */}

      {/* Call to Action */}
     

    </div>
     


      {/* Rest of the existing sections */}
      {/* <section>
        <Container>
          <Row>
            <Col lg="12" className="text-center">
              <h2>Vendors near you</h2>
            </Col>

            <Col lg="12">
              <div className="food__category d-flex align-items-center justify-content-center gap-4">
                <button
                  className={`d-flex align-items-center gap-2 ${
                    category === "BURGER" ? "foodBtnActive" : ""
                  } `}
                  onClick={() => setCategory("BURGER")}
                >
                  <img src={foodCategoryImg01} alt="" />
                  Burger
                </button>
              </div>
            </Col>

            {allProducts.map((item) => (
              <Col lg="3" md="4" sm="6" xs="6" key={item.id} className="mt-5">
                <ProductCard item={item} />
              </Col>
            ))}
          </Row>
        </Container>
      </section> */}
    </Helmet>
  );
};

export default Home;