import axios from 'axios';

// Create a new Axios instance
const api = axios.create({
  baseURL: 'http://localhost:3002/api/', // Replace with your API base URL
  timeout: 10000, // Set a default timeout (10 seconds in this case)
});

// Request interceptor
api.interceptors.request.use(
  (config) => {
    // Add authentication token to the request headers
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    // Log the request
    console.log('Request:', config.method.toUpperCase(), config.url);

    return config;
  },
  (error) => {
    // Handle request errors
    console.error('Request Error:', error.message);
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => {
    // Log the response
    console.log('Response:', response.status, response.config.url);

    // You can modify the response data here if needed
    return response;
  },
  (error) => {
    // Handle response errors
    if (error.response) {
      console.error('Response Error:', error.response.status, error.response.data);
      
      // Handle specific error codes
      switch (error.response.status) {
        case 401:
          // Unauthorized: Redirect to login or refresh token
          console.log('Unauthorized: Redirecting to login...');
          // Implement your logic here (e.g., redirect to login page)
          break;
        case 403:
          // Forbidden: Handle access denied
          console.log('Forbidden: Access denied');
          break;
        case 404:
          // Not Found: Handle resource not found
          console.log('Not Found: Resource not available');
          break;
        default:
          // Handle other error codes
          console.log('An error occurred');
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No Response Error:', error.request);
    } else {
      // Something happened in setting up the request that triggered an error
      console.error('Error:', error.message);
    }

    return Promise.reject(error);
  }
);

export default api;