import React from "react";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import logo from "../../assets/images/res-logo.png";

import "../../styles/footer.css";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
   
     {/* Footer */}
     <footer className="footer bg-dark text-white py-4">
     <Container>
       <Row>
         <Col md="4">
           <h5>OfXpress</h5>
           <p>
             Connecting you with local vendors, delivering convenience 
             and deliciousness to your doorstep.
           </p>
         </Col>
         <Col md="4">
           <h5>Quick Links</h5>
           <ul className="list-unstyled">
             <li><Link to="/home" className="text-white">Home</Link></li>
             <li><Link to="/vendors" className="text-white">Vendors</Link></li>
             <li><Link to="/support" className="text-white">Support</Link></li>
             <li><Link className="text-white" to="/privacy-policy">privacy policy</Link></li>
           </ul>
         </Col>
         <Col md="4">
           <h5>Contact Us</h5>
           <p>
             Email:  ofxpressapps@gmail.com<br />
             Phone:  2349034492806
           </p>
         </Col>
       </Row>
       <hr />
       <div className="text-center">
         <p>&copy; 2024 OfXpress. All Rights Reserved.</p>
       </div>
     </Container>
   </footer>
   </>
  );
};

export default Footer;
